
import { GoogleGenerativeAI } from "@google/generative-ai";

const genAI = new GoogleGenerativeAI("AIzaSyAPgu6iMg1gJBp8k6oMcyFlPueq4E3i70w");
const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });


const generateMessage = async (prompt) => {

    prompt = prompt ? "Generate me a message based on this prompt:: '" + prompt+".' If the prompt seems to be out of context, just generate me a message saying hi. Keep the message 3-4 sentences long" : 'Generate a short 3-4 sentenced message to me saying hi. My name is Sushil';
    console.log(prompt);
    const result = await model.generateContent(prompt);
    // console.log(result);
    return result.response.text()

}

export default generateMessage;