import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Particle from "../Particle";
import generateMessage from "../chat";
import spinner from "../../Assets/spinner.gif";

function Contact() {
  return (
    <section>
      <Container fluid className="contact-section" id="contact">
        <Particle />
        <Container className="contact-content">
          <Row>
            <Col md={12} className="contact-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Get in Touch!
              </h1>
              <p className="contact-description">
                Feel free to reach out to me for collaborations or just a
                friendly chat. I’ll get back to you as soon as possible!
              </p>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Form id='contactForm'>
                <Form.Group controlId="formName">
                  {/* <Form.Label>Name</Form.Label> */}
                  <Form.Control
                    type="text"
                    placeholder="Enter your Name*"
                    required
                  />
                    <span id="formNameError" class="error"></span> 
                </Form.Group>

                <Form.Group controlId="formEmail" className="mt-3">
                  {/* <Form.Label>Email</Form.Label> */}
                  <Form.Control
                    type="email"
                    placeholder="Enter your Email*"
                    required
                  />
                    <span id="formEmailError" class="error"></span> 

                </Form.Group>

                <Form.Group controlId="formPhone" className="mt-3">
                  {/* <Form.Label>Email</Form.Label> */}
                  <Form.Control type="tel" placeholder="Enter your Phone" />
                  <span id="formPhoneError" class="error"></span> 

                </Form.Group>

                <Form.Group controlId="formTitle" className="mt-3">
                  {/* <Form.Label>Email</Form.Label> */}
                  <Form.Control
                    type="title"
                    placeholder="Enter message title*"
                    required
                  />
                    <span id="formTitleError" class="error"></span> 

                </Form.Group>

                <Form.Group controlId="messageBox" className="mt-3">
                  {/* <Form.Label>Message</Form.Label> */}
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Write your message here or provide an outline of the message and click generate for a AI generated message*"
                    // id="messageBox"
                    required
                  />
                    <span id="messageBoxError" class="error"></span> 

                </Form.Group>

                <Button
                  id="submit-button"
                  variant="primary"
                  type="submit"
                  className="mt-4"
                  style={{ width: "40%", margin: "0 10px" }}
                  onClick={sendMessage}
                >
                  Submit
                  <img src={spinner} id="submitSpinner" />
                </Button>

                <Button
                  id="generate-button"
                  variant="primary"
                  type="submit"
                  className="mt-4"
                  style={{ width: "40%", margin: "0 10px" }}
                  onClick={generateText}
                >
                  Generate
                  <img src={spinner} id="generateSpinner" />
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

async function generateText(e) {
  console.log("Generating message");
  e.preventDefault();
  document.getElementById("generateSpinner").style.display = "inline-block";
  const prompt = document.getElementById("messageBox").value
    ? document.getElementById("messageBox").value
    : "";
  const text = await generateMessage(prompt);
  document.getElementById("messageBox").value = text;
  document.getElementById("generateSpinner").style.display = "none";
}

async function sendMessage(e) {
  console.log("Sending message");
  e.preventDefault();
  document.getElementById("submitSpinner").style.display = "inline-block";
  const form = document.getElementById("contactForm");
  if (form.checkValidity()) {
    const name = document.getElementById("formName").value;
    const email = document.getElementById("formEmail").value;
    const phone = document.getElementById("formPhone").value;
    const title = document.getElementById("formTitle").value;
    const message = document.getElementById("messageBox").value;
    let body = JSON.stringify({ name, email, phone, title, message })
    console.log('\nBody:: ',body);
    const postUrl = 'https://dbsdfqi6cd5ro7vvzkhuytslqa0fmkmb.lambda-url.us-east-1.on.aws/';
    const response = await fetch(postUrl, {
      mode: 'no-cors',
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body
    });
    console.log(response);
  } else {
    console.log("error");
    [...form.elements].forEach((input) => {
      const errorSpan = document.getElementById(`${input.id}Error`);
      if (errorSpan) {
        errorSpan.textContent = input.validationMessage; // Show the browser's default error message
      }
    });
  }
  document.getElementById("submitSpinner").style.display = "none";
}
export default Contact;
