import React from "react";
import Particles from "react-tsparticles";

function Particle() {
  return (
    <Particles
      id="tsparticles"
      params={{
        // fpsLimit: 60,
        // background: {
        //   color: "#0b032d"
        // },
        // backgroundMode: {
        //   enable: false
        // },
        particles: {
          color: {
            value: ["#f67e7d", "#843b62", "#621940"]
          },
          links: {
            color: "#ffb997",
            enable: true
          },
          move: {
            enable: true,
            speed: 0.1
          },
          size: {
            value: 8,
            random: {
              enable: true,
              minimumValue: 1
            },
            animation: {
              enable: true,
              speed: 1,
              minimumValue: 1
            }
          },
          opacity: {
            value: 0.8,
            random: {
              enable: true,
              opacity_min: 0.05,
            }
          }
        }
      }}
    />
  );
}

export default Particle;
