import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiGit,
  DiJava,
} from "react-icons/di";
import {
  SiRedis,
  SiPostgresql,
  SiSpringboot 
} from "react-icons/si";
import { FaAws } from "react-icons/fa6";



function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      
      <Col xs={4} md={2} className="tech-icons" title = 'Javascript'>
        <DiJavascript1 />
      </Col>
     
      <Col xs={4} md={2} className="tech-icons" title = 'NodeJS'>
        <DiNodejs />
      </Col>
      <Col xs={4} md={2} className="tech-icons" title='Java'>
        <DiJava />
      </Col>
      <Col xs={4} md={2} className="tech-icons" title='Spring Boot'>
        <SiSpringboot />
      </Col>
      <Col xs={4} md={2} className="tech-icons" title = 'React'>
        <DiReact />
      </Col>
      
      <Col xs={4} md={2} className="tech-icons" title = 'MongoDB'>
        <DiMongodb />
      </Col>
     
      <Col xs={4} md={2} className="tech-icons" title = 'Git'>
        <DiGit />
      </Col>
      <Col xs={4} md={2} className="tech-icons" title = 'AWS'>
        <FaAws />
      </Col>
      <Col xs={4} md={2} className="tech-icons" title = 'Redis'>
        <SiRedis />
      </Col>
      <Col xs={4} md={2} className="tech-icons" title = 'Postgres'>
        <SiPostgresql />
      </Col>
    </Row>
  );
}

export default Techstack;
